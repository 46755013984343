<script lang="ts" setup>
import { useUserStore, useUserProfileStore } from "@/store/userStore";

const localePath = useLocalePath();
const storeUserProfile = useUserProfileStore();
const router = useRouter();
const store = useUserStore();
const { isLoggedIn, user } = storeToRefs(store);
const { userProfile } = storeToRefs(storeUserProfile);

const isLoginModalVisible = ref(false);
const isOtpModalVisible = ref(false);
const isProfileModalVisible = ref(false);
const phone = ref("");

const navItems = [
  { to: "/", title: "الصفحة الرئيسية" },
  { to: "evaluation", title: " تقييم المركبات المستعملة" },
  { to: "inspection", title: "فحص المركبات" },
  { to: "warranty", title: "ضمان المركبات" },
  { to: "#", title: " أبحاث السوق" },
  { to: "branches", title: " مواقعنا" },
  { to: "contact", title: "تواصل معنا" },
];

async function handleLogout() {
  try {
    store.removeUserFromLocalStorage();
    storeUserProfile.removeUserProfileFromLocalStorage();
    router.push(localePath("/"));
  } catch (error) {
    console.log(error);
  }
}
</script>

<template>
  <BNavbar toggleable="lg" class="bg-white app-navbar">
    <BNavbarBrand :to="$localePath('/')">
      <img src="/assets/images/logo.png" alt="" />
    </BNavbarBrand>
    <BNavbarToggle target="nav-collapse" class="border-0" />
    <BCollapse id="nav-collapse" is-nav>
      <BNavbarNav class="me-auto gap-2">
        <BNavItem class="d-block d-md-none navitem-top-sm-only">
          <img src="/assets/images/logo.png" alt="" />
          <BNavbarToggle target="nav-collapse" class="border-0">
            <template #default="{ expanded }">
              <img
                src="/assets/images/cross.svg"
                width="20px"
                height="auto"
                alt=""
              />
            </template>
          </BNavbarToggle>
        </BNavItem>
        <BNavItem
          v-for="navItem in navItems"
          :key="navItem.title"
          :to="$localePath(navItem.to)"
          :class="{ 'active-link': $route.path === $localePath(navItem.to) }"
        >
          {{ navItem.title }}
        </BNavItem>
      </BNavbarNav>
      <BNavbarNav class="me-auto mb-2 mb-lg-0">
        <div v-if="isLoggedIn" class="nav-item dropdown">
          <div
            class="nav-link dropdown-toggle"
            id="navbarDropdownMenuLink"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BAvatar
              v-if="userProfile?.user_image"
              :src="userProfile?.user_image"
              rounded="circle"
              variant="secondary"
              class="p-0"
              size="62px"
            />
            <BAvatar
              v-else
              rounded="circle"
              variant="secondary"
              size="44px"
              class="p-0"
            >
              <img
                src="/assets/images/avatar-default.jpg"
                class="img-fluid"
                alt=""
              />
            </BAvatar>
          </div>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li>
              <a class="dropdown-item text-end" href="/dashboard"
                >لوحة التحكم</a
              >
            </li>
            <li>
              <a class="dropdown-item text-end" href="/dashboard/profile"
                >الملف الشخصي</a
              >
            </li>
            <li>
              <p
                class="px-3 py-1 pb-2 mt-2 bg-primary text-white rounded text-end"
                @click.prevent="handleLogout"
              >
                تسجيل الخروج
              </p>
            </li>
          </ul>
        </div>
        <BNavItem v-else>
          <BButton
            variant="primary"
            @click.stop="isLoginModalVisible = true"
            class="fs-16 fw-600 px-3 h-auto py-3"
            size="lg"
          >
            دخول / تسجيل جديد
          </BButton>
        </BNavItem>
      </BNavbarNav>
    </BCollapse>
  </BNavbar>
  <AppLoginModal
    v-model:visible="isLoginModalVisible"
    v-model:phone="phone"
    @success="isOtpModalVisible = true"
  />
  <AppOtpModal
    v-model:visible="isOtpModalVisible"
    :phone="phone"
    @success="phone = ''"
    @openProfileModal="
      () => {
        phone = '';
        isProfileModalVisible = true;
      }
    "
  />
  <AppProfileModal v-model:visible="isProfileModalVisible" />
</template>

<style lang="css" scoped>
.app-navbar {
  background-color: #ffffff;
  border-bottom: 1px solid rgba(195, 212, 233, 0.4);
  height: 100%;
  width: 100%;
  max-height: 92px;
  z-index: 1000;
  position: fixed;
  top: 0;
}

.app-navbar :deep(.nav-link) {
  font-family: inherit;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  text-align: right;
  color: #2d3291;
}

.app-navbar :deep(.navitem-top-sm-only) a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-toggle::after {
  margin-right: -0.5rem;
  content: "" !important;
}

.nav-item .dropdown:hover {
  background-color: inherit !important; /* Example: reset background color */
  color: inherit !important;
}

.dropdown-menu.show {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  padding: 10px !important;
}

.dropdown-item:hover {
  background-color: #2d3291;
  color: white !important;
  border-radius: 5px;
}

.active-link {
  color: #2d3291;
  background-color: #ffffff;
  border-bottom: 2px solid #2d3291; 
  padding: 10px 15px;
  border-radius: 0;
}

@media (min-width: 768px) and (max-width: 1325px) {
  .app-navbar :deep(.nav-link) {
    font-size: 8px;
  }
}
</style>
